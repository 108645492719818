* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Sora', sans-serif !important;
}

body>#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}