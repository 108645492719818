.ql-code-block-container {
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #e0e0e0;
  /* color: #fff; */
}

.article-content img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}